export default {
  data() {
    return {
      rules: {
        time: value => {
          if (!value) return true
          const pattern = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/
          return pattern.test(value) || 'Введите время в формате HH:mm. Например, 08:30'
        }
      }
    }
  },
  methods: {
    getScoreColor(score) {
      if (score >= 98) return "green";
      if (score >= 90) return "orange";
      else return "red";
    },
  }
}